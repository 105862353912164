@import './fonts/sfProDisplay.css';
@import '../../../../libs/ui/src/icons/engage-icons/engage-icons.css';

* {
    box-sizing: border-box;
}

body {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
    height: 100vh;
    margin: 0;
    padding: 0;
    background-color: #fff !important;
}

#root {
    height: 100%;
    width: 100%;
}
